import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"



const Index = () => (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/Nreal_Light_After_Sales_&_Warranty_US.pdf`}
    />
  </>
)

export default Index